.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 22px;
  font-weight: 800;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}

.privacy-policy {
  background-color: #f9f9f9;
  padding: 30px 0;
}

.page-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  overflow: auto;
}

.standout-checkbox input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border: 1px solid #333;
  background-color: #fff;
  margin-right: 1rem;
}

.standout-checkbox input[type="checkbox"]:checked {
  background-color: #333;
}

.service-panel {
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.service-panel:hover {
  background-color: #f5f5f5;
}

.preformatted-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.chatbot-interface {
  width: 100%;
  margin: 0 1rem auto;
}

.messages-container {
  display: flex;
  flex-direction: column;
}

.user-input-section {
  /* margin-bottom: 20px; */
  align-items: center;
}

.form-column {
  padding: 0 !important;
}

/* .form-column input{
  min-height:  auto !important;
  line-height: unset !important;
  padding: unset !important;
} */

.enter-button {
  margin-left: 1em;
  padding: 0 !important;
}

.enter-button button {
  padding: 15px 35px !important;
  height: auto !important;
  line-height: unset !important;
  text-transform: uppercase;
  border-radius: 5px;
}

.AI,
.User {
  max-width: 60%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.AI {
  background-color: #f8f9fa;
  align-self: flex-start;
}

.User {
  background-color: #e9ecef;
  align-self: flex-end;
}

.chat-history-box {
  margin-bottom: 20px;
  overflow-y: scroll;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #f1f1f1;
}

.chat-history-box::-webkit-scrollbar {
  width: 8px;
}

.chat-history-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.chat-history-box::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
}

.chat-history-box::-webkit-scrollbar-thumb:hover {
  background-color: #c0c0c0;
}

.section-padding-t45 {
  padding: 36px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-t90 {
    padding-top: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-t90 {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .user-input-section{
    flex-direction: column;
  }

  .enter-button{
    width: 100% !important;
    margin: 1em 0 0 !important;
  }

  .enter-button button{
    width: 100% !important;
  }
}

.startup-form-element {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .startup-form-element {
    margin-top: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .startup-form-element {
    margin-top: 15px;
  }
}

@media (min-width: 1025px) {
  .startup-form-element {
    margin-top: 20px;
  }
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.audio-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.audio-button:hover {
  background-color: #e0e0e0;
}

.audio-button.audio-enabled {
  background-color: #4CAF50;
  color: white;
}

.audio-button.audio-enabled:hover {
  background-color: #45a049;
}

.audio-button .iconify {
  font-size: 1.5rem;
  color: black;
}

@keyframes blink {
  0% { opacity: 0.4; }
  50% { opacity: 0.1; }
  100% { opacity: 0.4; }
}

.audio-button.audio-initializing {
  animation: blink 1s infinite;
}
